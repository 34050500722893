import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './st.locale/localeSlice'
import selectedAction from './selectedAction/selectedActionSlice'
import clients from './ve.clients/clientsSlice'
import ventes from './ve.ventes/ventesSlice'
import fournisseurs from './ap.fournisseurs/fournisseursSlice'
import achats from './ap.achats/achatsSlice'
import livraisons from './st.livraisons/livraisonsSlice'
import receptions from './st.receptions/receptionsSlice'
import stocks from './st.stocks/stocksSlice'
import articles from './st.articles/articlesSlice'
import equipements from './eq.equipements/equipementsSlice'
import demandesMaintenances from './eq.demandesMaintenances/demandesMaintenancesSlice'
import immobilisationsAquises from './im.immobilisationsAquises/immobilisationsAquisesSlice'
import immobilisationsCédées from './im.immobilisationsCédées/immobilisationsCédéesSlice'
import cheques from './tr.cheques/chequesSlice'
import chequesFr from './tr.ChequesFr/chequesFrSlice'
import effetsClients from './tr.effetsClients/effetsClientsSlice'
import effetsFournisseurs from './tr.effetsFournisseurs/effetsFournisseursSlice'
import recouvrements from './rh.recouvrements/recouvrementsSlice'
import autresPps from './tr.autresPps/autresPpsSlice'
import avoirsClients from './compta.avoirsClients/avoirsClientsSlice'
import avoirsFournisseurs from './compta.avoirsFournisseurs/avoirsFournisseursSlice'
import facturesClients from './compta.facturesClients/facturesClientsSlice'
import facturesFournisseurs from './compta.facturesFournisseurs/facturesFournisseursSlice'
import resultats from './compta.resultats/resultatsSlice'
import budgets from './compta.budgets/budgetsSlice'
import journals from './compta.journals/journalsSlice'
import conges from './rh.conges/congesSlice'
import employes from './rh.employes/employesSlice'
import paies from './rh.paies/paiesSlice'
import postes from './rh.postes/postesSlice'
import recrutements from './rh.recrutements/recrutementsSlice'
import calendrierMaintenances from './eq.calendrierMaintenances/calendrierMaintenancesSlice'
import statistiquesAchats from './ap.statistiquesAchats/statistiquesAchatsSlice'
import statistiquesVentes from './ve.statistiquesVentes/statistiquesVentesSlice'
import statistiquesTresoreries from './tr.statistiquesTresoreries/statistiquesTresoreriesSlice'
import tableauBords from './tableauBords/tableauBordsSlice'
import livraisonsRetours from './st.livraisonsRetours/livraisonsRetoursSlice'
import receptionsRetours from './rh.receptionsRetours/receptionsRetoursSlice'
import locauxs from './st.locauxs/locauxsSlice'
import banques from './tr.banques/banquesSlice'
import balances from './tr.balances/balancesSlice'
import configs from './tr.configs/configsSlice'
import etats from './compta.etats/etatsSlice'
import paiesComptas from './rh.paiesComptas/paiesComptasSlice'
import globales from './globales/globalesSlice'
import informations from './config.informations/informationsSlice'
import crms from './config.crms/crmsSlice'
import conditions from './config.conditions/conditionsSlice'
import utilisateurs from './utilisateurs/utilisateursSlice'
import transferts from './st.transferts/transfertsSlice'
import niveauxs from './inscriptions.niveauxs/niveauxsSlice'
import eleves from './inscriptions.eleves/elevesSlice'
import anneScolaires from './inscriptions.anneScolaires/anneScolairesSlice'
const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        selectedAction,
        clients,
        ventes,
        fournisseurs,
        achats,
        livraisons,
        receptions,
        stocks,
        articles,
        equipements,
        demandesMaintenances,
        immobilisationsAquises,
        immobilisationsCédées,
        cheques,
        chequesFr,
        effetsClients,
        effetsFournisseurs,
        recouvrements,
        autresPps,
        avoirsClients,
        avoirsFournisseurs,
        facturesClients,
        facturesFournisseurs,
        resultats,
        budgets,
        journals,
        conges,
        employes,
        paies,
        postes,
        recrutements,
        calendrierMaintenances,
        statistiquesAchats,
        statistiquesVentes,
        statistiquesTresoreries,
        tableauBords,
        livraisonsRetours,
        receptionsRetours,
        locauxs,
        banques,
        etats,
        paiesComptas,
        globales,
        configs,
        balances,
        informations,
        crms,
        conditions,
        utilisateurs,
        transferts,
        niveauxs,
        eleves,
        anneScolaires,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
