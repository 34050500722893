import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'

const { creation } = notificationConfig

// Redux Toolkit slice
const anneScolairesSlice = createSlice({
    name: 'anneScolaires',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsAnneScolaires: (state) => {
            if (state.detailsAnneScolaire == null) {
                state.detailsAnneScolaire = ''
            } else {
                state.detailsAnneScolaire = null
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsAnneScolaires.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsAnneScolaires.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsAnneScolaire = action.payload
            })
            .addCase(fetchDetailsAnneScolaires.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchAnneScolaires.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchAnneScolaires.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchAnneScolaires.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createAnneScolaire.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createAnneScolaire.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createAnneScolaire.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchAnneScolaires = createAsyncThunk(
    'anneScolaires/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/inscriptions.anneScolaires.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch anneScolaires')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsAnneScolaires = createAsyncThunk(
    'AnneScolaires/fetchDetails',
    async (id) => {
        try {
            const AnneScolaire = await BaseService.get(
                `/XML/inscriptions.anneScolaires.load/details?id=${id.id}`
            )
           

            return AnneScolaire.data
        } catch (error) {
            throw new Error('Failed to fetch AnneScolaires')
        }
    }
)

export const createAnneScolaire = createAsyncThunk(
    'anneScolaires/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/inscriptions.anneScolaires.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            setSubmitting(false)
            popNotification(creation?.error)
        }
    }
)

export const editAnneScolaire = createAsyncThunk('anneScolaires/edit', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/inscriptions.anneScolaires.send/edit', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })

        popNotification(creation?.error)
    }
})

export const selectData = (state) => state.anneScolaires.data
export const selectIsLoading = (state) => state.anneScolaires.isLoading
export const selectError = (state) => state.anneScolaires.error
export const selectIsDeleted = (state) => state.anneScolaires.isDeleted
export const selectCurrentPage = (state) => state.anneScolaires.currentPage
export const resetDetailsAnneScolaires = anneScolairesSlice.actions.resetDetailsAnneScolaires
export default anneScolairesSlice.reducer
