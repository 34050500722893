import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'

const { creation } = notificationConfig

// Redux Toolkit slice
const elevesSlice = createSlice({
    name: 'eleves',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        resetDetailsEleves: (state) => {
            if (state.detailsEleve == null) {
                state.detailsEleve = ''
            } else {
                state.detailsEleve = null
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsEleves.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsEleves.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsEleve = action.payload
            })
            .addCase(fetchDetailsEleves.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchEleves.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchEleves.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchEleves.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })




            
            .addCase(importEleves.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(importEleves.fulfilled, (state, action) => {
                state.isLoading = false
            })
            .addCase(importEleves.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })


            .addCase(createEleve.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createEleve.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createEleve.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchEleves = createAsyncThunk(
    'eleves/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/inscriptions.eleves.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch eleves')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsEleves = createAsyncThunk(
    'Eleves/fetchDetails',
    async (id) => {
        try {
            const Eleve = await BaseService.get(
                `/XML/inscriptions.eleves.load/details?id=${id.id}`
            )
           

            return Eleve.data
        } catch (error) {
            throw new Error('Failed to fetch Eleves')
        }
    }
)

export const createEleve = createAsyncThunk(
    'eleves/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/inscriptions.eleves.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate(-1)
            }
        } catch (err) {
            console.log({ err })
            setSubmitting(false)
            popNotification(creation?.error)
        }
    }
)

export const editEleve = createAsyncThunk('eleves/edit', async (payload) => {
    const { data, formik, navigate, setClientAdded } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.post('/XML/inscriptions.eleves.send/edit', data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(creation?.success)
            setClientAdded(true)
        }
    } catch (err) {
        console.log({ err })

        popNotification(creation?.error)
    }
})




export const importEleves = createAsyncThunk(
    'fournisseur/import',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            // const response = await BaseService.post(`/XML/inscriptions.eleves.send/import`, data)
            const response = await BaseService.post(`/XML/inscriptions.eleves.send/importServicesMonths`, data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.eleves.data
export const selectIsLoading = (state) => state.eleves.isLoading
export const selectError = (state) => state.eleves.error
export const selectIsDeleted = (state) => state.eleves.isDeleted
export const selectCurrentPage = (state) => state.eleves.currentPage
export const resetDetailsEleves = elevesSlice.actions.resetDetailsEleves
export default elevesSlice.reducer
