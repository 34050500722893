import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const locauxsSlice = createSlice({
    name: 'locauxs',
    initialState: {
        data: [],
        nomsLocauxs: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        startLoading: (state) => {
            state.isLoading = true
        },
        stopLoading: (state) => {
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchDetailsLocauxs.pending, (state) => {
            state.isLoading = true
            state.error = null
        })
        .addCase(fetchDetailsLocauxs.fulfilled, (state, action) => {
            const currentPage = action?.meta?.arg?.page || state.currentPage;

            state.currentPage = currentPage
            state.isLoading = false
            state.data = action.payload
        })
        .addCase(fetchDetailsLocauxs.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message
        })
            .addCase(fetchLocauxs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchLocauxs.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchLocauxs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchNomsLocauxs.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchNomsLocauxs.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.nomsLocauxs = action.payload
            })
            .addCase(fetchNomsLocauxs.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createLocaux.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createLocaux.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createLocaux.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteLocaux.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteLocaux.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteLocaux.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchLocauxs = createAsyncThunk(
    'locauxs/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/st.locaux.load?page=${page}&limit=${limit}&text=${search}&statut_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch locauxs')
        }
    }
)

export const fetchNomsLocauxs = createAsyncThunk(
    'nomsLocauxs/fetch',
    async () => {
       
        try {
            const response = await BaseService.get(
                `/XML/st.locaux.load/load_all_locaux`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch locauxs')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsLocauxs = createAsyncThunk(
    'Locauxs/fetchDetails',
    async (id) => {
        const data = {'idfr':id.id}
        try {
            const Locaux = await BaseService.post(
                `/XML/ap.fr.load/get_one_fr`,data
            )
            const contact = await BaseService.post(
                `/XML/ap.fr.load/get_contact`,data
            )

            return {Locaux: Locaux.data, contact: contact.data}
        } catch (error) {
            throw new Error('Failed to fetch Locauxs')
        }
    }
)
export const createLocaux = createAsyncThunk(
    'locauxs/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/st.locaux.send/dataAjouter', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)
export const deleteLocaux = createAsyncThunk(
    'locaux/delete',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/st.locaux.send/dataSupprimer', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)


export const stockMinLocaux = createAsyncThunk(
    'locaux/stocksMin',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/st.locaux.send/dataAdd_sm', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)
export const editLocaux = createAsyncThunk('locauxs/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/locauxs/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/locauxs')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})



export const selectData = (state) => state.locauxs.data
export const selectIsLoading = (state) => state.locauxs.isLoading
export const selectError = (state) => state.locauxs.error
export const selectIsDeleted = (state) => state.locauxs.isDeleted
export const selectCurrentPage = (state) => state.locauxs.currentPage
export const startLoading = locauxsSlice.actions.startLoading
export const stopLoading = locauxsSlice.actions.stopLoading

export default locauxsSlice.reducer
