import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'
import { hierarchicalGroup } from '../../functions/hierarchicalGroup'
import {hierarchicalGroupBalance} from '../../functions/hierarchicalGroupBalance'
import Decimal from 'decimal.js'
const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const etatsSlice = createSlice({
    name: 'etats',
    initialState: {
        data: [],
        exercices: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {
        startLoading (state) {
            state.isLoading = true
        },
        stopLoading (state) {
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEtats.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchEtats.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchEtats.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchExerciceEtats.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchExerciceEtats.fulfilled, (state, action) => {
                state.isLoading = false
                state.exercices = action.payload
            })
            .addCase(fetchExerciceEtats.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(createEtat.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(createEtat.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(createEtat.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
            .addCase(deleteEtat.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteEtat.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteEtat.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchEtats = createAsyncThunk(
    'etats/fetch',
    async (payload) => {
        const exercice = payload?.exercice || ''
        const plan = payload?.plan || {}
        const etat = payload?.etat || ''

        const data = { 'exercice': exercice }
        try {
            const response = await BaseService.post(
                `/XML/compta.et.load/${etat}`, data
            )
            let arrBilan = []
            if (etat == 'bilan') {
                const resultat = new Decimal(response?.data?.pr || 0).minus(response?.data?.ch || 0).toFixed(2)
                if (Number(resultat) > 0) {
                    response.data.fp.push({ compte: '1191', description: "1191 Résultat net de l'exercice (Solde créditeur)", solde: Number(resultat) })
                } else {
                    response.data.fp.push({ compte: '1199', description: "1199 Résultat net de l'exercice (Solde débiteur)", solde: Number(resultat) })
                }

                arrBilan.push(hierarchicalGroup((response.data.fp || 0), plan)?.[0])
                arrBilan.push(hierarchicalGroup((response.data.ai || 0), plan)?.[0])
                arrBilan.push(hierarchicalGroup((response.data.ac || 0), plan)?.[0])
                arrBilan.push(hierarchicalGroup((response?.data?.pc || 0), plan)?.[0])
                arrBilan.push(hierarchicalGroup((response?.data?.tra || 0), plan)?.[0])
                arrBilan.push(hierarchicalGroup((response?.data?.trp || 0), plan)?.[0])
            } else if(etat == 'cpc') {
                arrBilan.push(hierarchicalGroup(response.data.charges, plan)?.[0])
                arrBilan.push(hierarchicalGroup(response.data.produits, plan)?.[0])
            }else if(etat == 'ESG'){
                const obj = {
                    AchatRevendueMrchandise: "Achat revendu marchandise",
                    venteMarchandiseEnEtat: "Vente marchandise en état",
                    margeBruteVenteEnEtat: "Marge brute vente en état",
                    venteBienEtServicesProduits: "Vente biens et services produits",
                    variationStocksProduits: "Variation stocks produits",
                    immoProduitPourElleMemme: "Immo produit pour elle-même",
                    production: "Production",
                    AchatConsommes: "Achats consommés",
                    autresChargesExterne: "Autres charges externes",
                    consommation: "Consommation",
                    VA: "Valeur ajoutée",
                    subventionExp: "Subventions d'exploitation",
                    impotEtTaxe: "Impôts et taxes",
                    chargesPersonnel: "Charges de personnel",
                    EBE: "Excédent brut d'exploitation",
                    autresProduitsExploitation: "Autres produits d'exploitation",
                    repriseExploitation: "Reprises d'exploitation",
                    autresChargesExploitation: "Autres charges d'exploitation",
                    dotationExploitation: "Dotations d'exploitation",
                    resultatExploitation: "Résultat d'exploitation",
                    resultatFinancier: "Résultat financier",
                    resultatCourant: "Résultat courant",
                    resultatNonCourant: "Résultat non courant",
                    is: "Impôt sur les sociétés",
                    resultatNet: "Résultat net",
                    caf: "Capacité d'autofinancement"
                  };
                Object.keys(response.data).forEach((key) => {
                    arrBilan.push({ description: obj[key], solde: response.data[key] })
                })
            }else{
                const arr = []
                Object.keys(response.data).forEach((key) => {
                    arr.push(response.data[key])
                })
                arrBilan = hierarchicalGroupBalance(arr,plan)
            }



            return arrBilan
        } catch (error) {
            console.log({ error })
            throw new Error('Failed to fetch etats')
        }
    }
)

// Async thunk action to fetch details from the API
export const fetchExerciceEtats = createAsyncThunk(
    'etats/fetchDetails',
    async (id) => {

        try {
            const data = await BaseService.post(
                `/XML/compta.resultat.load/get_n_exercices`, {}
            )

            const plan = await BaseService.post(
                `/XML/compta.jr.load.js/getPlanComptable`, {}
            )
            const objPlan = {}
            plan.data.forEach(element => {
                objPlan[element.ncompte] = element.nomenclature
            });


            return { years: data.data, plan: objPlan }
        } catch (error) {
            throw new Error('Failed to fetch etats')
        }
    }
)

export const createEtat = createAsyncThunk(
    'etats/post',
    async (payload) => {
        const { data, formik, navigate } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/etats', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                navigate('/etats')
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editEtat = createAsyncThunk('etats/edit', async (payload) => {
    const { data, formik, navigate } = payload
    const { resetForm, setSubmitting } = formik
    try {
        const response = await BaseService.put(`/etats/${data?.id}`, data)
        if (response.status === 200) {
            resetForm()
            setSubmitting(false)
            popNotification(edit?.success)
            navigate('/etats')
        }
    } catch (err) {
        console.log({ err })
        
        popNotification(edit?.error)
    }
})

export const deleteEtat = createAsyncThunk(
    'etat/delete',
    async (payload) => {
        const { id, closeDialog } = payload
        if (!id) return
        try {
            const response = await BaseService.delete(`/etats/${id}`)
            if (response.status === 200 || response.status === 201) {
                popNotification(deletion?.success)
                closeDialog()
                return true
            }
        } catch (err) {
            console.log({ err })
            popNotification(deletion?.error)
            return
        }
    }
)

export const selectData = (state) => state.etats.data
export const selectIsLoading = (state) => state.etats.isLoading
export const selectError = (state) => state.etats.error
export const selectIsDeleted = (state) => state.etats.isDeleted
export const selectCurrentPage = (state) => state.etats.currentPage
export const startLoading = etatsSlice.actions.startLoading
export const stopLoading = etatsSlice.actions.stopLoading
export default etatsSlice.reducer
