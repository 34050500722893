import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import BaseService from 'services/BaseService'
import { popNotification } from 'utils/popNotification'
import { notificationConfig } from './constants'
import { LIMIT } from 'constants/app.constant'
import { formatDateSend } from '../../functions/formatDateSend'

const { creation, edit, deletion } = notificationConfig

// Redux Toolkit slice
const chequesSlice = createSlice({
    name: 'cheques',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
        created: null,
        isDeleted: null,
        currentPage: 1,
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsCheques.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchDetailsCheques.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.detailsCheques = action.payload
            })
            .addCase(fetchDetailsCheques.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(fetchCheques.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchCheques.fulfilled, (state, action) => {
                const currentPage = action?.meta?.arg?.page || state.currentPage;

                state.currentPage = currentPage
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchCheques.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
            })
            .addCase(encaisserCheque.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(encaisserCheque.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(encaisserCheque.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })
        
            .addCase(addCheque.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(addCheque.fulfilled, (state, action) => {
                state.isLoading = false
                state.created = true
            })
            .addCase(addCheque.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.created = false
            })




            .addCase(deleteCheque.pending, (state) => {
                state.isLoading = true
                state.isDeleted = null
            })
            .addCase(deleteCheque.fulfilled, (state) => {
                state.isLoading = false
                state.isDeleted = true
            })
            .addCase(deleteCheque.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.isDeleted = false
            })
    },
})

// Async thunk action to fetch data from the API
export const fetchCheques = createAsyncThunk(
    'cheques/fetch',
    async (payload) => {
        const page = payload?.page || 1
        const limit = payload?.limit || LIMIT
        const search = payload?.search || ''
        const statut_f = payload?.statut_f || ''
        let date_d_f = payload?.date?.[0] || ''
        let date_f_f = payload?.date?.[1] || ''
        if (dayjs(date_d_f).isValid() && dayjs(date_f_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
        } else if (dayjs(date_d_f).isValid()) {
            date_d_f = dayjs(date_d_f).format('YYYY-MM-DD');
            date_f_f = date_d_f;
        } else if (dayjs(date_f_f).isValid()) {
            date_f_f = dayjs(date_f_f).format('YYYY-MM-DD');
            date_d_f = date_f_f;
        }
        try {
            const response = await BaseService.get(
                `/XML/tr.cheque.load?page=${page}&limit=${limit}&text=${search}&etat_f=${statut_f}&date_d_f=${date_d_f}&date_f_f=${date_f_f}`
            )

            return response.data
        } catch (error) {
            throw new Error('Failed to fetch cheques')
        }
    }
)
// Async thunk action to fetch details from the API
export const fetchDetailsCheques = createAsyncThunk(
    'Cheques/fetchDetails',
    async (id) => {
        const data = { 'idc': id.id, 'side': id.side }
        try {
            const Cheque = await BaseService.post(
                `/XML/tr.cheque.load/get_one_cheque`, data
            )
            let pp
            let type
            if (Cheque.data[0].idpp.split('_')[0] == 'C') {
                pp = await BaseService.post(
                    `/XML/ve.cl.load/get_one_cl`, { 'idcl': Cheque.data[0].idpp.split('_')[1] }
                )
                type = 'Client'
            } else if (Cheque.data[0].idpp.split('_')[0] == 'F') {
                pp = await BaseService.post(
                    `/XML/ap.fr.load/get_one_fr`, { 'idfr': Cheque.data[0].idpp.split('_')[1] }
                )
                type = 'fournisseur'
            } else {

            }
            const dic_etat = {
                '0': 'En portefeuille',
                '1': "À l'encaissement",
                '2': 'Encaisser'
            }
            if (Cheque.data[0] && typeof id.side !== 'undefined') {
                Cheque.data[0]['etat'] = dic_etat[Cheque.data[0]['etat']]
            }

            return { Cheque: Cheque.data[0], pp: pp.data, type: type }
        } catch (error) {
            throw new Error('Failed to fetch Cheques')
        }
    }
)

export const encaisserCheque = createAsyncThunk(
    'cheques/post',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.cheque.send/mise_encaissement_cheque', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const addCheque = createAsyncThunk(
    'cheques/add',
    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.cheque.send/add', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const confCheque = createAsyncThunk('cheques/conf',

    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('/XML/tr.cheque.send/typeEncaissement_cheque', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)

export const editCheque = createAsyncThunk('cheques/edit',

    async (payload) => {
        const { data, formik, navigate, setClientAdded } = payload
        const { resetForm, setSubmitting } = formik
        try {
            const response = await BaseService.post('XML/tr.cheque.send/typeModification_cheque', data)
            if (response.status === 200) {
                resetForm()
                setSubmitting(false)
                popNotification(creation?.success)
                setClientAdded(true)
            }
        } catch (err) {
            console.log({ err })
            
            setSubmitting(false)
            
            popNotification(creation?.error)
        }
    }
)








export const deleteCheque = createAsyncThunk(
    'cheque/delete',
    async (payload) => {
        const { data, setChequeAdded } = payload

        try {
            const response = await BaseService.post('/XML/tr.cheque.send/delete_tr_cheque', data)
            if (response.status === 200) {


                popNotification(creation?.success)
                setChequeAdded(true)
            }
        } catch (err) {
            console.log({ err })
            

            
            popNotification(creation?.error)
        }
    }
)

export const selectData = (state) => state.cheques.data
export const selectIsLoading = (state) => state.cheques.isLoading
export const selectError = (state) => state.cheques.error
export const selectIsDeleted = (state) => state.cheques.isDeleted
export const selectCurrentPage = (state) => state.cheques.currentPage

export default chequesSlice.reducer
